<template>
    <BaseContainer>
        <span slot="principal">
            <v-content>
                <v-card elevation="0" class="mb-12">
                    <v-card-title class="justify-center">
                        {{ form.id ? 'Edição' : 'Cadastro' }} de Colaborador
                    </v-card-title>
                    <v-card-text align="center" justify="center">
                        <div>
                            <v-text-field
                                v-model="form.name"
                                label="Nome"
                                outlined
                                dense
                                hide-details
                            />
                            <v-text-field
                                v-model="form.email"
                                label="Email"
                                type="email"
                                class="mt-3"
                                outlined
                                dense
                                hide-details
                            />
                            <v-text-field
                                v-model="form.nova_senha"
                                label="Nova Senha"
                                :type="showPassword ? 'text' : 'password'"
                                @click:append="toggleShowPassword('showPassword')"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                class="mt-3"
                                outlined
                                dense
                                hide-details
                            />
                            <v-text-field
                                v-model="form.repetir_senha"
                                label="Repetir Senha"
                                :type="showPasswordConfirm ? 'text' : 'password'"
                                @click:append="toggleShowPassword('showPasswordConfirm')"
                                :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                                class="mt-3"
                                outlined
                                dense
                                hide-details
                            />
                            <v-select
                                v-model="form.admin"
                                :items="[{value: true, text: 'Sim'}, {value: false, text: 'Não'}]"
                                label="Administrador"
                                class="mt-3"
                                outlined
                                dense
                                hide-details
                            />
                        </div>
                    </v-card-text>
                </v-card>
                <v-footer fixed class="pa-4">
                    <v-btn link large text color="warning" to="/colaboradores">Cancelar</v-btn>
                    <v-spacer />
                    <v-btn color="success" large @click="salvar" :loading="loading">
                        <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                    </v-btn>
                </v-footer>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';

export default {
    name: 'Colaborador',

    components: { BaseContainer },

    data: () => ({
        loading: false,
        form: {
            id: '',
            name: '',
            email: '',
            nova_senha: '',
            repetir_senha: '',
            admin: '',
            showPassword: false,
            showPasswordConfirm: false,
        },
    }),

    mounted() {
        let dados = this.$store.getters.getData;

        if (dados?.id) {
            this.form = dados;
            this.$store.commit('setData', {});
        }
    },

    methods: {
        salvar() {
            const id = this.form.id;

            if (!id && !this.form.nova_senha) {
                this.notify('Informe uma senha', 'warning');
                return;
            }

            if (this.form.nova_senha && this.form.nova_senha != this.form.repetir_senha) {
                this.notify('Senhas não conferem', 'warning');
                return;
            }

            if (id) {
                this.loading = true;
                this.$http.put(`usuarios/${id}`, this.form).then(() => {
                    this.$router.push('/colaboradores');
                    this.notify('Atualizado com sucesso!');
                }).catch(() => {
                    this.notify('Não foi possivel concluir a operação', 'warning');
                }).finally(() => (this.loading = false));
                return;
            }

            this.loading = true;
            this.$http.post('usuarios', this.form).then(() => {
                this.$router.push('/colaboradores');
                this.notify('Salvo com sucesso!');
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.loading = false));
        },

        toggleShowPassword(field) {
            this[field] = !this[field];
            this.$forceUpdate();
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
